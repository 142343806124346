.navbar {
	background-color: rgba(250, 250, 250, 0.9);

	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #ddd;

		h1 {
			margin-bottom: 0;

			small {
				color: #999;
				font-weight: normal;
				font-size: .6em;
				margin-left: 15px;
			}
		}

		@media screen and (max-width: 1023px) {
			h1 {
				display: inline;
				font-size: 1.5rem;
			}
		}

		@media screen and (max-width: 480px) {
			h1 {
				font-size: 1.25rem;

				small {
					display: none;
				}
			}
		}
	}
}

.app {
	padding-top: 30px;
	padding-bottom: 30px;
}
